.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  /* boostrap form style */
  
  .form-inline {
    width: 100%;
  }
  
  .form-group {
    width: 90%;
  }
  
  .input-group {
    width: 90% !important;
  }
  
  .form-control {
    width: 80% !important;
  }

  textarea.form-control {
    width: 100% !important;
  }
  
  span.input-group-addon {
    width: 50px !important;
  }
  
  .center {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5%;
  }

  .tableContainer{
    height:75vh;
    width: 100%;
    overflow: scroll;
  }

  .table thead th{
    background-color: rgb(43,48,53);
    color: white;
    position: sticky;
    margin: 0 0 0 0;
  }

  input[type="checkbox"][disabled][checked] {
    filter: invert(100%) hue-rotate(18deg) brightness(5);
  }
  input[type="checkbox"][disabled] {
    filter: invert(100%) hue-rotate(18deg) brightness(5);
  }

  .filter-col {
    border-right: 1px solid black;
  }